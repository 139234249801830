.footer {
	position: relative;
	width: 100%;
	min-height: $height_footer;
	padding: 8.2rem $gl_indent 2em;
	flex: 0 0 auto;

	font-size: 1rem;

	will-change: transform;

	&:before {
		position: absolute;
		top: 0;
		left: 50%;

		content: '';

		width: .8em;
		height: 5.4em;
		margin-left: -.4em;

		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: contain;

		background-image: url("../i/decors/line_deco_2.png");
	}

	@include bp($point_3) {
		padding-left: 0;
		padding-right: 0;
	}
}

.footer_in {}

.footer_top {
	margin-bottom: 4em;

	@include bp($point_2, min) {
		padding: 0 3.8em;
	}

	@include bp($point_3) {
		padding: 0 $gl_indent_mob;
	}
}
.footer_top_title {
	@extend %global_flex_block_row_wrap_flex-start;

	position: relative;
	padding-bottom: 4px;
}

.footer_top_title_in {
	$fz: 3;
	position: relative;

	color: $gray;
	font-size: $fz + em;
	font-weight: 400;
	padding-bottom: (.8em/$fz);
	line-height: .8;

	&:before {
		position: absolute;
		top: 100%;
		left: 0;

		content: '';

		width: (5.4em/$fz);
		height: (.8em/$fz);

		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: contain;
		background-image: url("../i/decors/line_deco.png");
	}
}

.footer_body {
	@extend %global_flex_block_row_wrap_flex-start;

	position: relative;

	width: 100%;
	margin-bottom: 2em;


	@include bp($point_2, min) {
		padding: 3.8em 3.8em 8.4em;
	}

	@include bp($point_3) {
		padding: 3.8em $gl_indent_mob 8.4em;
	}

	background-color: $gray;

	&:before {
		position: absolute;
		bottom: 1em;
		right: 1em;

		content: '';

		width: 5em;
		height: 5em;

		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: contain;
		background-image: url("../i/decors/small_dots_w.png");
	}
}

.footer_body_col {
	@extend %global_flex_block_column_wrap_flex-start;

	@include bp($point_2, min) {
		width: 25%;
		padding-right: 2em;
	}

	@include bp($point_3) {
		width: 100%;

		margin-bottom: 3em;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.footer_logo {
	width: 18em;
	margin-bottom: 1.6em;
}

.footer_body_info {
	$fz: 2.6;

	width: 100%;

	color: $white;
	font-size: $fz + em;
	font-weight: 400;
	line-height: 1.1;

	p {
		margin-bottom: (2em/$fz);

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.footer_body_link {
	$fz: 2.6;

	@extend %transition_color;

	text-decoration: none;
	color: $white;
	font-size: $fz + em;
	font-weight: 400;
	line-height: .8;

	&:hover, &:active, &:focus {
		text-decoration: none;
		color: $orange;
	}
}

.footer_bottom {

	@include bp($point_2, min) {
		padding: 0 3.8em;
	}

	@include bp($point_3) {
		padding: 0 $gl_indent_mob;
	}
}

.footer_copy {
	font-size: 1.8em;
	color: $black;
	line-height: .8;
}