//---------------------------------------------sections
.section {
	position: relative;

	width: 100%;
	padding: 6em $gl_indent;

	font-size: 1rem;

	&.top_decor_mod {
		&:before {
			position: absolute;
			top: 0;
			left: 50%;

			content: '';

			width: .8em;
			height: 5.4em;
			margin-left: -.4em;

			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: contain;

			background-image: url("../i/decors/line_deco_2.png");
		}
	}

	@include bp($point_3) {
		padding: 6em $gl_indent_mob;
	}
}

.section_in {
	max-width: $page_width;
	width: 100%;
	margin: 0 auto;
}

.section_title {
	$fz: 7.6;

	width: 100%;

	.section_title_w & {
		width: auto;
	}

	font-size: $fz + em;
	font-weight: 400;
	line-height: 1;
	color: $gray;

	&.offset_mod {
		margin-bottom: (5.6em/ $fz);
	}

	span {
		color: $orange;
	}

	&.size_mod {
		font-size: 3em;
	}

	&.decor_mod {
		position: relative;

		&:before {
			position: absolute;
			top: 100%;
			left: 0;

			content: '';

			width: (5.6em/4);
			height: (.8em/4);

			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: contain;
			background-image: url("../i/decors/line_deco.png");
		}
	}

	@include bp($point_3) {
		font-size: 5em;
		text-align: center;
	}

}

.section_descr {
	$fz: 3;

	width: 100%;

	font-size: $fz + em;
	color: $gray_4;
	line-height: 1.16;
	&.offset_mod {
		margin-bottom: (2.6em/$fz);
	}

	@include bp($point_3) {
		text-align: center;
	}
}

.section_title_w {
	@extend %global_flex_block_row_wrap_flex-start_center;
	margin: 4em 0 5em;

	@include bp($point_3) {
		justify-content: center;
	}
}