.contacts_list_w {
	position: relative;

	width: 100%;

	@include bp($point_2, min) {
		margin-left: 13em;

		&:before {
			position: absolute;
			left: -7em;
			top: 1em;

			content: '';

			width: 4em;
			height: 4em;

			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: contain;
			background-image: url("../i/decors/dots_small.png");
		}
	}
}

.contacts_list {
	width: 100%;

	margin: 0;
	padding: 0;

	list-style: none;
}

.contact_item {
	margin-bottom: 5em;

	&:last-child {
		margin-bottom: 0;
	}
}

.contact_item_in {
	@extend %global_flex_block_row_nowrap_flex-start_flex-end;
}

.contact_item_link {
	$fz: 3.4;

	@extend %transition_color;

	text-decoration: none;
	color: #231f20;
	font-size: $fz + em;
	font-weight: 400;
	line-height: .8;

	&:hover, &:active, &:focus {
		text-decoration: none;
		color: $orange;
	}

	@include bp($point_4 - 1) {
		font-size: 2.6em;
	}
}