.section_catalog_w {
	position: relative;

	@include bp($point_2, min) {
		margin-left: auto;
	}
}

.section_decor_img_w {
	@include bp($point_2, min) {
		position: absolute;
		top: 0;
		left: 0;

		width: 39.4em;
		height: 35em;
	}

	@include bp($point_3) {
		display: none;
	}
}

.section_decor_img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: contain;
}

.section_catalog_decor_w {
	position: relative;
	width: 100%;
	height: 13em;

	@include bp($point_3) {
		display: none;
	}
}

.section_catalog_decor {
	position: absolute;
	bottom: 0;
	left: 50%;

	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: contain;

	&.v1_mod {
		width: 12em;
		height: 11em;
		margin: 0 0 0 -6.6em;

		background-image: url("../i/icons/catalog_icon.png");
	}

	&.v2_mod {
		width: 6.6em;
		height: 6.6em;
		margin: 0 0 0 8.8em;

		background-image: url("../i/decors/dots.png");
	}
}

.section_catalog_bottom {
	position: relative;
	width: 100%;
	height: 3.6em;
	margin-top: 6.8em;

	background-color: $gray_3;

	&:before {
		position: absolute;
		top: 50%;
		right: 1.2em;

		content: '';

		width: 12em;
		height: 2.8em;
		margin-top: -1.4em;

		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: contain;

		background-image: url("../i/logo.png");
	}
}

.section_catalog_content {
	$fz: 3;

	@include bp($point_2, min) {
		width: 100%;
		padding-left: 4em;
	}


	p {
		font-weight: 400;
		font-size: $fz + em;
		line-height: 1;
		color: $gray_2;
	}

	ul {
		padding-left: 1.4em;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		align-items: flex-start;
		align-content: flex-start;
	}

	li {
		position: relative;

		font-weight: 400;
		font-size: $fz + em;
		line-height: 1;
		color: $gray_2;

		&:before {
			position: absolute;
			top: (1.3em/$fz);
			left: (-1.2em/$fz);

			content: '';

			width: 3px;
			height: 3px;
			border-radius: 50%;
			background-color: $orange;
		}
	}
}