@mixin st_btn() {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	white-space: nowrap;

	&:hover, &:focus {
		text-decoration: none;
	}

}

//color extends
%bg_btn_1 {
	font-weight: 400;
	color: $white;
	background: $gray;
	transition: .3s ease;

	@include st_btn();

	&:hover {
		background: darken($gray, 10%);
	}

	&.accent_mod {
		background-color: $orange;
	}

}

//size extends
%btn_size_1 {
	$fz: 2.4;

	min-width: (30em/$fz);
	height: (3.6em/$fz);
	padding: 0 (1.2em/$fz);

	line-height: (3.6em/$fz);
	font-size: $fz + em;
}