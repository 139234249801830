//---------------------------------------------fonts
$font_path: "../fonts/";
$font_1: 'Futura', sans-serif;
$font_size_base: 1.6rem;
//---------------------------------------------fonts###

//---------------------------------------------colors
$white: #fff;
$black: #000;

$color_text_base: $black;
$color_link_base:#4aa3df;
$body_bg: $white;
$gray: #6d6e70;
$gray_2: #6c6d6f;
$gray_3: #e7e7e9;
$gray_4: #332f30;
$gray_5: #d1d2d4;
$orange: #f25822;
//---------------------------------------------colors###

//---------------------------------------------z-index
$z-index: (
	popap : 9999,
	header : 100,
	footer : 100
);
//---------------------------------------------z-index###

//---------------------------------------------layout
$height_footer: 42.8rem;
$height_header: 10rem;
$page_width: 122rem;

//---------------------------------------------layout###

//---------------------------------------------global values
$gl_indent: 4rem;
$gl_indent_mob: 2rem;
$gl_radius:.5rem;
//---------------------------------------------global values###

//---------------------------------------------media points
$point_1: 1200px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;
$point_9: 1367px;
$point_10: 1600px;
$point_11: 1440px;
//---------------------------------------------media points

//---------------------------------------------forms
$input_height: 4rem;
$input_offset: 0 2rem;
$input_font_size: $font_size_base;
$input_border_color: #ccc;
$input_border_color_active: $black;
$input_placeholder_color: $black;
$input_text_color: $black;
$input_radius: $gl_radius;
//---------------------------------------------forms###