.section_content {
	@extend %global_flex_block_row_wrap_flex-start_stretch;

	width: 100%;

	&.offset_mod {
		margin-bottom: 8.6em;
	}

	&.offset_2_mod {
		margin-bottom: 6.8em;
	}

	@include bp($point_3) {
		position: relative;
	}
}

.section_content_col {
	position: relative;
	width: 50%;

	@include bp($point_2, min) {
		.height_mod & {
			min-height: 37.2em;
		}

		.height_2_mod & {
			min-height: 26.8em;
		}
	}

	&:first-child {
		@include bp($point_2, min) {
			padding-right: 1em;
		}
	}

	&.flex_mod {
		@extend %global_flex_block_row_wrap_flex-start_flex-end;

		@include bp($point_2, min) {
			min-height: 35em;
		}
		padding: 0;

		@include bp($point_3) {
			justify-content: center;
			margin-bottom: 3em;
		}
	}

	&.flex_2_mod {
		@extend %global_flex_block_column_wrap_flex-end;

		@include bp($point_3) {
			justify-content: flex-start;
			align-items: center;
			align-content: center;
			//margin-bottom: 3em;
		}
	}

	@include bp($point_3) {
		width: 100%;


		&:last-child {
			&:not(.flex_2_mod):not(.contact_mod) {
				z-index: -1;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				opacity: .2;
			}
		}
	}
}

.section_content_img_w {
	&:not(.pos_mod) {
		width: 100%;
	}

	&.pos_mod {
		position: absolute;
		@include bp($point_2, min) {
			top: 0;
			left: 0;
		}

		@include bp($point_3) {
			top: -6em;
			left: 50%;

			transform: translateX(-50%);
		}
	}

	@include bp($point_2, min) {
		&.pos_1 {
			top: -6.8em;
		}

		&.pos_2 {
			top: -12em;
		}

		&.pos_3 {
			top: -6em;
			left: 5.4em;
		}
	}

	&.size_1 {
		z-index: 10;
		
		width: 66em;
		height: 62em;

		@include bp($point_6) {
			font-size: 2.1vw;
		}
	}

	&.size_2 {
		width: 100%;
		height: 46em;
	}

	&.size_3 {
		width: 50em;
		height: 50em;
	}
}

.section_content_img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
}

.info_cards_w {
	width: 100%;
}

.info_cards_list {
	@extend %global_flex_block_row_wrap_flex-start_stretch;

	margin: 0 -1.6em -1.6em 0;
	padding: 0;

	list-style: none;
}

.info_card_item {
	padding: 0 1.6em 1.6em 0;

	@include bp($point_2, min) {
		width: 25%;
	}

	@media (min-width: $point_4) and (max-width: $point_3) {
		width: 50%;
	}

	@include bp($point_4 - 1) {
		width: 100%;
	}
}

.info_card_item_in {
	@extend %global_flex_block_column_wrap_flex-start;
	@extend %transition_background;

	position: relative;

	width: 100%;
	height: 100%;

	background-color: $gray_3;

	text-decoration: none;

	&:not(.v1_mod) {
		min-height: 29.6em;
		padding: 4em 3em 11em 4em;

		&:before {
			position: absolute;
			right: 1.4em;
			bottom: 1.4em;

			content: '';

			width: 7.6em;
			height: 8.6em;

			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: contain;
			background-image: url("../i/decors/dots.png");
		}
	}

	&.v1_mod {
		padding: 3em 6.6em 3em 4em;

		&:before {
			position: absolute;
			right: 1.4em;
			bottom: 1.4em;

			content: '';

			width: 5em;
			height: 5em;

			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: contain;
			background-image: url("../i/decors/dots_small.png");
		}
	}

	&:hover, &:active, &:focus {
		text-decoration: none;

		@include bp($point_2, min) {
			background-color: $orange;

			&:not(.v1_mod) {
				&:before {
					background-image: url("../i/decors/dots_w.png");
				}
			}

			&.v1_mod {
				&:before {
					background-image: url("../i/decors/small_dots_w.png");
				}
			}
		}
	}
}

.info_card_title {
	$fz: 3.4;

	width: 100%;
	margin-top: auto;

	font-size: $fz + em;
	color: $black;
	font-weight: 400;
	line-height: 1;

	&:not(.no_decor_mod) {
		position: relative;

		padding: 0 0 4px;

		&:before {
			position: absolute;
			top: 100%;
			left: 0;

			content: '';

			width: (5.6em/$fz);
			height: (.8em/$fz);

			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: contain;
			background-image: url("../i/decors/line_deco.png");
		}

		@include bp($point_2, min) {
			.info_card_item_in:hover &, .info_card_item_in:active &, .info_card_item_in:focus & {
				color: $white;

				&:before {
					background-image: url("../i/decors/line_decor_w.png");
				}
			}
		}
	}

	@include bp($point_2, min) {
		.info_card_item_in:hover &, .info_card_item_in:active &, .info_card_item_in:focus & {
			color: $white;
		}
	}
}