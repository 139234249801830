.how_to_list {
	@extend %global_flex_block_row_wrap_flex-start_stretch;

	margin: 0 -1.6em -4.4em 0;
}

.how_to_item {
	@include bp($point_2, min) {
		width: 25%;
	}

	@media (min-width: $point_4) and (max-width: $point_3) {
		width: 50%;
	}

	@include bp($point_4 - 1) {
		width: 100%;
	}
	padding: 0 1.6em 4.4em 0;
	//overflow: hidden;
}

.how_to_item_in {
	display: block;
	width: 100%;
	height: 100%;

	text-decoration: none;
	 overflow: hidden;

	&:hover, &:active, &:focus {
		text-decoration: none;
	}
}

.how_to_item_head {
	//@extend %transition_background;

	position: relative;

	width: 100%;
	padding: 3.4em 7.8em 3.4em 4.8em;

	background-color: $gray_3;

	&:before {
		position: absolute;
		right: 1.4em;
		bottom: 1.4em;

		content: '';

		width: 4.4em;
		height:4.4em;

		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: contain;
		background-image: url("../i/decors/dots_small.png");
	}

	&:after {
		position: absolute;
		right: 1.4em;
		bottom: 1.4em;

		content: '';

		width: 4.4em;
		height:4.4em;

		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: contain;
		background-image: url("../i/decors/small_dots_w.png");

		opacity: 0;
	}

	@include bp($point_2, min) {
		.how_to_item_in:hover &, .how_to_item_in:active &, .how_to_item_in:focus & {
			background-color: $orange;

			&:before {
				opacity: 0;
			}

			&:after {
				opacity: 1;
			}
		}
	}
}

.how_to_item_title {
	//@extend %transition_color;

	$fz: 3.4;

	width: 100%;

	font-size: $fz + em;
	color: $black;
	font-weight: 400;
	line-height: 1;

	@include bp($point_2, min) {
		.how_to_item_in:hover &, .how_to_item_in:active &, .how_to_item_in:focus & {
			color: $white;
		}
	}
}

.how_to_item_body {
	@extend %global_flex_block_column_nowrap_flex-start;

	position: relative;

	width: 100%;
	height: 46em;

	background-repeat: repeat-y;
	background-position: 50% 50%;
	background-size: contain;
	background-image: url("../i/how_item_bg.jpg");

	overflow: hidden;
}

.how_to_item_descr {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: calc(100% - 20.6em);

	//flex: 1 0 auto;
	@include bp($point_2, min) {
		background-color: $orange;
		opacity: 0;
		.how_to_item_in:hover &, .how_to_item_in:active &, .how_to_item_in:focus & {
			opacity: 1;
		}
	}

	@include bp($point_2) {
		background-color: $gray_3;
	}

	//max-height: 0;
	overflow: hidden;

}

.how_to_item_descr_in {
	$fz: 2.4;

	width: 100%;
	padding: (1em/$fz) (2em/$fz) (3em/$fz);

	font-size: $fz + em;

	font-weight: 400;
	line-height: 1;

	@include bp($point_2, min) {
		color: $white;
	}

	@include bp($point_3) {
		color: $black;
	}
}

.how_to_item_img_w {
	position: absolute;
	left: 0;
	width: 100%;
	height: 20.6em;

	@include bp($point_2, min) {
		top: 0;

		.how_to_item_in:hover &, .how_to_item_in:active &, .how_to_item_in:focus & {
			top: auto;
			bottom: 0;
		}
	}

	@include bp($point_3) {
		bottom: 0;
	}
}

.how_to_item_img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;

	@include bp($point_2, min) {
		filter: grayscale(100%);
		transition: .3s ease;
		.how_to_item_in:hover &, .how_to_item_in:active &, .how_to_item_in:focus & {
			filter: grayscale(0);
		}
	}
}

.gallery_list_w {
	width: 100%;
	padding-top: 20px;
}

.gallery_list {
	@extend %global_flex_block_row_wrap_flex-start;

	margin: 0 -1.6em -1.6em 0;
	padding: 0;

	list-style: none;
}

.gallery_item {
	@include bp($point_2, min) {
		width: 25%;
	}

	@media (min-width: $point_4) and (max-width: $point_3) {
		width: 50%;
	}

	@include bp($point_4 - 1) {
		width: 100%;
	}
	padding: 0 1.6em 1.6em 0;
}

.gallery_item_link {
	display: block;
	width: 100%;
	height: 19.6em;

	text-decoration: none;

	overflow: hidden;

	&:hover, &:active, &:focus {
		text-decoration: none;
	}
}

.gallery_item_img {
	@extend %transition_transform;

	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;

	.gallery_item_link:hover & {
		transform: scale(1.1);
	}
}