.btn_w {
	@extend %global_flex_block_row_wrap_flex-start;

	width: 100%;

	&.right_pos_mod {
		@include bp($point_2, min) {
			justify-content: flex-end;
		}
	}

	&.offset_mod {
		margin-top: 7em;
	}

	@include bp($point_3) {
		justify-content: center;
	}
}

.btn_base {
	@extend %bg_btn_1;
	@extend %btn_size_1;
}