//------------------------------------------------------------layout
.header {
	position: fixed;
	top: 0;
	left: 0;

	@include bp($point_2, min) {
		height: $height_header;
		padding: 0 $gl_indent;
	}

	@include bp($point_3) {
		height: 8rem;
		padding: 0 $gl_indent_mob;
	}

	width: 100%;

	background-color: $body_bg;

	font-size: 1rem;

	will-change: transform;

	@include z-index(header);

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;

		width: 100%;
		height: 100%;

		transition: all .3s ease;
		opacity: 0;

		box-shadow: 0 1em 2em 0 rgba($gray, .5);

		content: '';
	}

	&.scroll_mod {
		&:before {
			opacity: 1;
		}

	}
}

.header_in {
	@extend %global_flex_block_row_nowrap_flex-start_center;

	height: 100%;
}

.header_logo {
	@include bp($point_2, min) {
		width: 26em;
	}

	@include bp($point_3) {
		width: 16em;
	}
}

.logo {
	display: block;
	width: 100%;
	height: auto;
}

.header_nav_w {

	@include bp($point_2, min) {
		@include flex_block(row, nowrap, flex-start, center, center);
		margin-left: auto;
	}

	@include bp($point_3) {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100vh;
		max-height: 100vh;
		padding: $height_header;

		overflow-x: hidden;
		overflow-y: auto;

		background: $gray_5;
		transform: translate(0, -100%);
		transition: transform .3s ease;
		will-change: transform;

		@include flex_block(column, nowrap, center, center);

		body.menu_open & {
			transform: translate(0, 0);
		}
	}
}

.header_nav {
	@include bp($point_2, min) {
		margin-right: 2em;
	}

	@include bp($point_3) {
		order: 2;
	}
}

.header_nav_list {
	@extend %global_flex_block_row_nowrap_flex-start;

	margin: 0;
	padding: 0;

	list-style: none;

	@include bp($point_3) {
		display: block;
	}
}

.header_nav_item {
	@include bp($point_3) {
		margin-bottom: 2em;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.header_nav_link {
	$fz: 2.5;
	@extend %transition_color;

	position: relative;

	display: block;

	@include bp($point_2, min) {
		padding: 0 (3em/$fz) 4px;
	}

	@include bp($point_3) {
		padding-bottom: 4px;
	}

	font-size: $fz + em;
	line-height: .8;
	font-weight: 400;
	color: $gray;
	text-decoration: none;

	&:before {
		@extend %transition_background;

		position: absolute;
		top: 100%;
		left: 0;

		content: '';

		width: 100%;
		height: (.8em/$fz);

		@include bp($point_2, min) {
			background-color: $gray_3;
		}
	}

	&:hover, &:active, &:focus {
		text-decoration: none;
		color: $orange;
	}

	&.active_link {
		color: $orange;

		&:before {
			background-color: $orange;
		}
	}
}

//------------------------------------------------------------layout###
.header_lang_w {
	@include bp($point_3) {
		margin-bottom: 3em;
		padding: 1em;
		border-bottom: 1px solid $gray;
	}
}

.dropdown_lang {
	position: relative;

	width: 100%;
	max-width: 4.4em;
	margin: 0;
	padding: 0;

	list-style: none;
}

.dropdown_lang_item {
	position: relative;

	width: 100%;
	padding-right: 2em;

	&:after {
		@extend %transition_transform;
		position: absolute;
		top: 0;
		right: 0;

		content: "";

		width: 1em;
		height: 1em;

		background-image: url("../i/decors/arrow_down.svg");
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: contain;
		opacity: 0.4;

		.dropdown_lang:hover & {
			transform: rotate(-180deg);
		}
	}
}

.dropdown_lang_title {
	//text-decoration: none;
	$fz: 2;
	@extend %transition_color;


	font-size: $fz + em;
	color: $gray;
	font-weight: 400;
	line-height: 0.8;
	text-transform: uppercase;
	letter-spacing: 0.038rem;

	cursor: pointer;

	//&:hover, &:active, &:focus {
	//	text-decoration: none;
	//}
}

.dropdown_lang_sub_list {
	@extend %transition_opacity;

	position: absolute;
	width: 100%;
	left: 0;
	top: 100%;

	opacity: 0;
	pointer-events: none;

	background-color: $gray;

	.dropdown_lang:hover & {
		opacity: 1;
		pointer-events: auto;
	}
}

.dropdown_lang_sub_list_item {
	padding: 0 4px;
}

.dropdown_lang_sub_list_link {
	text-decoration: none;
	color: $white;
	font-family: $font_1;
	font-size: 1.5em;
	font-weight: 700;
	line-height: (2.6/1.5);
	text-transform: uppercase;
	letter-spacing: 0.038rem;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}
}

//---------------------------------------------trigger
@mixin user_menu_butt_line {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: .4rem;
	margin-top: -.2rem;
	background: black;
}

.menu_trigger {
	z-index: 210;
	position: absolute;
	top: 50%;
	right: $gl_indent_mob;
	display: none; // hidden on wide screens
	height: 2.6rem;
	width: 3.8rem;
	margin-top: -1.6rem;
	text-transform: uppercase;

	color: transparent;
	cursor: pointer;

	@include bp($point_3) {
		display: block; // appear on tablets
	}

	&:before {
		content: '';
		transform: translate(0, -1rem);
		transition: all .3s ease;

		@include user_menu_butt_line;
	}
	&:after {
		content: '';
		transform: translate(0, 1rem);
		transition: all .3s ease;

		@include user_menu_butt_line;
	}
	&.active_mod {
		&:before {
			transform: (rotate(45deg) translate(0, 0));
		}

		&:after {
			transform: (rotate(-45deg) translate(0, 0));
		}
	}
}

.menu_trigger_decor {
	transition: all .3s ease;

	.active_mod & {
		opacity: 0;
		transform: translate(100%, 0);
	}

	@include user_menu_butt_line;
}

//---------------------------------------------trigger###