//---------------------------------------------icons
.icon_el {
	display: block;

	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: contain;

	&.contact_mod {
		width: 3.4em;
		height: 4em;
		margin-left: 1.2em;

		background-image: url("../i/icons/contacte_icon.png");
	}

	&.contact_2_mod {
		margin-right: 1em;

		@include bp($point_4, min) {
			width: 8.6em;
			height: 5.4em;
		}

		@include bp($point_4 - 1) {
			width: 4.3em;
			height: 2.7em;
		}
	}

	&.footer_icon_mod {
		width: 6em;
		height: 4em;
		margin: 0 0 1.8em;
	}

	&.info_card_mod {
		margin-bottom: 2.6em;
	}

	&.phone_mod {
		background-image: url("../i/icons/phone.png");
	}

	&.email_mod {
		background-image: url("../i/icons/mail.png");
	}

	&.facebook_mod {
		background-image: url("../i/icons/fb.png");
	}

	&.phone_2_mod {
		background-image: url("../i/icons/phone2.png");
	}

	&.email_2_mod {
		background-image: url("../i/icons/mail2.png");
	}

	&.facebook_2_mod {
		background-image: url("../i/icons/fb2.png");
	}

	&.promo_icon_mod {
		width: 7.6em;
		height: 7.6em;

		background-image: url("../i/icons/promogift_icon.png");

		@include bp($point_2, min) {
			.info_card_item_in:hover &, .info_card_item_in:active &, .info_card_item_in:focus & {
				background-image: url("../i/icons/promosuvenire_w.png");
			}
		}
	}

	&.t_shirt_icon_mod {
		width: 8em;
		height: 7.6em;

		background-image: url("../i/icons/textile_icon.png");

		@include bp($point_2, min) {
			.info_card_item_in:hover &, .info_card_item_in:active &, .info_card_item_in:focus & {
				background-image: url("../i/icons/textile_w.png");
			}
		}
	}

	&.book_icon_mod {
		width: 6.6em;
		height: 7.6em;

		background-image: url("../i/icons/birotica_icon.png");

		@include bp($point_2, min) {
			.info_card_item_in:hover &, .info_card_item_in:active &, .info_card_item_in:focus & {
				background-image: url("../i/icons/birotica_w.png");
			}
		}
	}

	&.print_icon_mod {
		width: 9em;
		height: 7.6em;

		background-image: url("../i/icons/tipar_icon.png");

		@include bp($point_2, min) {
			.info_card_item_in:hover &, .info_card_item_in:active &, .info_card_item_in:focus & {
				background-image: url("../i/icons/tipar_w.png");
			}
		}
	}

	&.title_icon_mod {
		width: 4.2em;
		height: 4.2em;
		margin-left: 1.2em;

		background-image: url("../i/icons/icon_cum.png");
	}

	&.look_mod {
		position: absolute;
		top: 0;
		left: 0;

		width: 7em;
		height: 7em;

		background-image: url("../i/icons/catalog_icon.png");
	}


}