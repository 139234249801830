.look_block {
	@extend %global_flex_block_row_wrap_flex-start_stretch;

	width: 100%;
}

.look_block_col {
	&:first-child {
		position: relative;

		min-height: 68.2em;
		width: 33.45%;

		@include bp($point_3) {
			display: none;
		}
	}

	&:last-child {
		@include bp($point_2, min) {
			width: 66.55%;
		}

		@include bp($point_3) {
			width: 100%;
		}
	}
}

.look_block_img_w {
	z-index: -1;

	position: absolute;
	top: 0;
	left: 0;

	width: 81.2em;
	height: 100%;
}

.look_block_img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: contain;
}

.look_block_list {
	@extend %global_flex_block_row_wrap_flex-end_stretch;

	@include bp($point_2, min) {
		margin: 0 -5em 20em 0;
	}

	@media (min-width: $point_4) and (max-width: $point_3) {
		margin: 0 -2em 10em 0;
	}

	@include bp($point_4 - 1) {
		margin-bottom: 4em;
	}

}

.look_block_item {
	width: 50%;

	@include bp($point_2, min) {
		padding-right: 5em;
	}

	@media (min-width: $point_4) and (max-width: $point_3) {
		padding-right: 2em;
	}

	@include bp($point_4 - 1) {
		width: 100%;
		margin-bottom: 2em;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.look_block_item_in {
	@extend %transition_opacity;
	position: relative;

	display: block;
	width: 100%;
	height: 100%;
	min-height: 39.6em;
	padding-left: 9.6em;

	text-decoration: none;

	&:hover, &:active, &:focus {
		text-decoration: none;
		opacity: .8;
	}
}

.look_item_title_w {
	position: absolute;
	bottom: 0;
	left: 4em;

	width: 100%;
	max-width: 31.4em;
	margin-bottom: -3em;

	transform: rotate(270deg);
	transform-origin: 0 0;

	overflow: hidden;
	white-space: nowrap;
}

.look_item_title {
	$fz: 3.4;

	font-size: $fz + em;
	color: $black;
	font-weight: 400;
	line-height: 1;
}

.look_item_img_w {
	width: 100%;
	height: 100%;
}

.look_item_img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
}

.look_block_bottom_info {
	@extend %global_flex_block_row_wrap_flex-end;

	position: relative;

	width: 100%;

	@include bp($point_3) {
		justify-content: center;
	}
}

.look_block_bottom_info_text {
	$fz: 3;

	position: relative;

	width: 100%;
	max-width: 50em;

	@include bp($point_2, min) {
		&:before {
			position: absolute;
			right: 100%;
			top: 1em;

			content: '';

			width: 7.4em;
			height: 8em;
			margin-right: 4.8em;

			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: contain;
			background-image: url("../i/decors/dots.png");
		}
	}

	p {
		font-weight: 400;
		font-size: $fz + em;
		line-height: 1;
		color: $gray_2;
	}

	ul {
		padding-left: 1.4em;
	}

	li {
		position: relative;

		font-weight: 400;
		font-size: $fz + em;
		line-height: 1;
		color: $gray_2;

		&:before {
			position: absolute;
			top: (1.3em/$fz);
			left: (-1.2em/$fz);

			content: '';

			width: 3px;
			height: 3px;
			border-radius: 50%;
			background-color: $orange;
		}
	}
}