.catalog_list_w {
	width: 100%;
}

.catalog_list {
	width: 100%;
	margin: 0;
	padding: 0;

	list-style: none;
}

.catalog_item {
	width: 100%;
	margin-bottom: 1.6em;

	&:last-child {
		margin-bottom: 0;
	}
}

.catalog_item_in {
	position: relative;
	display: block;

	width: 100%;
	height: 100%;
	padding: .8em 0 0 3.6em;

	text-decoration: none;

	@include bp($point_3) {
		padding-left: 0;
	}

	@include bp($point_2, min) {
		&:before {
			z-index: -1;

			position: absolute;
			top: 0;
			left: 0;

			content: '';

			width: 75%;
			height: 100%;

			background-color: #e7e7e9;

			pointer-events: none;
			opacity: 0;

			transition: opacity .3s ease;
			will-change: opacity;
		}

		&:after {
			position: absolute;
			bottom: 0;
			left: 3.6em;

			content: '';

			width: 5.6em;
			height: .8em;

			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: contain;
			background-image: url("../i/decors/line_deco.png");
		}
	}

	&:hover, &:active, &:focus {
		text-decoration: none;
		&:before {
			opacity: 1;
		}
	}
}

.catalog_item_content {
	@extend %global_flex_block_row_wrap_flex-start_stretch;

	@include bp($point_2, min) {
		margin-left: -3.6em;
	}
}

.catalog_item_title {
	$fz: 3.4;

	position: relative;

	width: 100%;
	padding: 0 0 4px;
	//margin-bottom: 1em;

	font-size: $fz + em;
	color: $black;
	font-weight: 400;
	line-height: 1;

	&:before {
		position: absolute;
		top: 100%;
		left: 0;

		content: '';

		width: (5.6em/$fz);
		height: (.8em/$fz);

		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: contain;
		background-image: url("../i/decors/line_deco.png");
	}

}

.catalog_item_descr_w {
	@extend %global_flex_block_row_wrap_flex-start_stretch;

	@include bp($point_2, min) {
		width: 75%;
	}

	@include bp($point_3) {
		width: 100%;
	}
	//height: 100%;
}

.catalog_item_descr {
	$fz: 3.4;

	@include bp($point_2, min) {
		width: calc(100% - 3.4rem);
		padding: 2rem 3rem 4.4rem 3.6rem;
	}


	font-size: $fz + em;
	color: $black;
	font-weight: 400;
	line-height: 1;

	@include bp($point_3) {
		padding: (2em/2.4) 0;
		font-size: 2.4em;
	}
}

.catalog_item_side_info {
	position: relative;

	background-color: $gray_5;

	@include bp($point_2, min) {

		width: 3.4em;
		height: 100%;
		min-height: 20.6em;

		&:before,
		&:after {

			position: absolute;

			content: '';

			border-radius: 50%;

			pointer-events: none;
			opacity: 0;

			transition: opacity .3s ease;
			will-change: opacity;
		}

		&:before {
			top: -3.2em;
			right: -3.2em;

			width: 6.6em;
			height: 6.6em;

			border: 1em solid $orange;
		}

		&:after {
			bottom: -1.4em;
			left: -1em;

			width: 2.6em;
			height: 2.6em;

			border: 4px solid $orange;
		}

		.catalog_item_in:hover &, .catalog_item_in:active &, .catalog_item_in:focus & {
			&:before, &:after {
				opacity: 1;
			}
		}
	}

	@include bp($point_3) {
		width: 100%;
		height: 3.4em;
	}
}

.catalog_item_side_info_title {
	position: absolute;
	top: 50%;
	left: 50%;

	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;

	width: 2em;
	height: 2em;
	margin: -1em 0 0 -1em;

	text-align: center;
	white-space: nowrap;

	@include bp($point_2, min) {
		transform: rotate(-90deg);
	}
}

.catalog_item_side_info_title_in {
	font-size: 3em;
	font-weight: 400;
	line-height: .8;
	color: $orange;
}

.catalog_item_img_w {
	width: 25%;
	height: 100%;
	padding: .6em;

	@include bp($point_3) {
		display: none;
	}
}

.catalog_item_img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
}